<template>
  <div>
    <el-card class="register">
      <div class="clearfix" slot="header">
        <span>
          {{ ifEmpty ? "添加" : "修改"
          }}{{ billOrAddress ? "发票" : "收件" }}信息
        </span>
        <el-button @click.native="onCancel" style="float: right; padding: 3px 0" type="text">关闭</el-button>
      </div>
      <el-form :model="info.enterpriseInfos[0]" label-width="80px" ref="form" v-if="billOrAddress">
       
        <el-form-item
          :rules="[
            { required: true, message: '请输入企业名称', trigger: 'blur' }
          ]"
          label="企业名称"
          prop="name"
        >
          <el-input v-model="info.enterpriseInfos[0].name"></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            { required: true, message: '请输入信用代码', trigger: 'blur' }
          ]"
          label="信用代码"
          prop="creditCode"
        >
          <el-input
            :ref="refList.cardInput"
            @input="inputBlur(info.enterpriseInfos[0].creditCode)"
            v-model="info.enterpriseInfos[0].creditCode"
          ></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            {  message: '请输入注册地址', trigger: 'blur' }
          ]"
          label="注册地址"
          prop="registerAddress"
        >
          <el-input v-model="info.enterpriseInfos[0].registerAddress"></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            {  message: '请输入注册电话', trigger: 'blur' },
          ]"
          label="注册电话"
          prop="phone"
        >
          <el-input v-model="info.enterpriseInfos[0].phone"></el-input>
        </el-form-item>

        <el-form-item
          :rules="[
            {  message: '请输入开户行', trigger: 'blur' }
          ]"
          label="开户行"
          prop="accountOpening"
        >
          <el-input v-model="info.enterpriseInfos[0].accountOpening"></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            {  message: '请输入银行账号', trigger: 'blur' }
          ]"
          label="银行账号"
          prop="bankAccount"
        >
          <el-input
            :ref="refList.cardInputs"
            @input="
              formatCardNumber(
                refList.cardInputs,
                info.enterpriseInfos[0].bankAccount,
                info
              )
            "
            v-model="info.enterpriseInfos[0].bankAccount"
          ></el-input>
        </el-form-item>



        <el-form-item>
          <el-button @click="onSubmit" type="primary">
            {{
            ifEmpty ? "立即添加" : "立即修改"
            }}
          </el-button>

          <el-button @click="onCancel">
            {{
            ifEmpty ? "取消添加" : "取消修改"
            }}
          </el-button>
        </el-form-item>
      </el-form>

      <el-form label-position="left" :model="postAddress" label-width="80px" ref="form2" v-else>
        <el-form-item
          :rules="[
            {  message: '店名', trigger: 'blur' }
          ]"
          label="店名"
          prop="companyName"
        >
          <el-input v-model="postAddress.companyName"></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            { required: true, message: '请输入收件地址', trigger: 'blur' }
          ]"
          label="收件地址"
          prop="address"
        >
          <el-input v-model="postAddress.address"></el-input>
        </el-form-item>

        <el-form-item
          :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
          label="收件人"
          prop="name"
        >
          <el-input v-model="postAddress.name"></el-input>
        </el-form-item>

        <el-form-item
          :rules="[
            { required: true, message: '请输入电话', trigger: 'blur' },
            { pattern: /^1[34578]\d{9}$/, message: '电话号输入有误' }
          ]"
          label="收件电话"
          prop="phone"
        >
          <el-input v-model="postAddress.phone"></el-input>
        </el-form-item>

        <el-form-item>
          <div class="items">
            <el-button @click="postSubmit" type="primary">
              {{
              ifEmpty ? "立即添加" : "立即修改"
              }}
            </el-button>

            <el-button @click="onCancel">
              {{
              ifEmpty ? "取消添加" : "取消修改"
              }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Bus from "../../utils/bus.js";
const info = JSON.parse(window.sessionStorage.getItem("user-token"));
export default {
  data () {
    return {
      ifEmpty: "",

      refList: {
        cardInput: "cardInput",
        cardInputs: "cardInputs"
      },
      // info: {
      //   loginPhone: '',
      //   password: '',
      //   accountOpening: '',
      //   bankAccount: '',
      //   consignee: '',
      //   creditCode: '',
      //   enterpriseName: '',
      //   harvestAddress: '',
      //   phoneNumber: '',
      //   registeredAddress: '',
      //   telephone: ''
      // },
      info: {
        loginPhone: "",
        password: "",
        userName: "",
        email: "",
        enterpriseInfos: [
          {
            id: "",
            accountOpening: "",
            bankAccount: "",
            phone: "",
            creditCode: "",
            name: "",
            registerAddress: "",
            enterpriseUser: {
              id: ""
            }
          }
        ],
        enterpriseAddresses: [
          {
            name: "",
            address: "",
            phone: "",

          }
        ]
      },
      postAddress: {
        id: "",
        companyName: "",
        address: "",
        name: "",
        phone: "",
        enterpriseUser: {
          id: ""
        }
      }
    };
  },
  created () {
    this.ifEmpty = JSON.parse(this.$route.query.ifEmpty);
  },
  computed: {
    billOrAddress () {
      return this.$store.state.billOrAddress;
    }
  },
  mounted () {
    let empty = JSON.parse(this.$route.query.ifEmpty);

    console.log(empty);
    if (!empty) {
      this.ifEmpty = empty;
      let datas = this.$route.query.datas;
      console.log("ppp", datas);
      //修改发票  显示所需要的信息。
      if (!this.ifEmpty && this.billOrAddress) {
        console.log("ssdafsfasf", datas);
        this.info.enterpriseInfos[0].accountOpening = datas.accountOpening;
        this.info.enterpriseInfos[0].id = datas.id;
        this.info.enterpriseInfos[0].bankAccount = datas.bankAccount;
        this.info.enterpriseInfos[0].phone = datas.phone;
        this.info.enterpriseInfos[0].creditCode = datas.creditCode;
        this.info.enterpriseInfos[0].name = datas.name;
        this.info.enterpriseInfos[0].registerAddress = datas.registerAddress;
        this.info.enterpriseInfos[0].enterpriseUser.id =
          info.enterpriseUserDTO.id;
      }
      // 修改邮寄地址 所需要显示的数据
      if (!this.billOrAddress) {
        this.postAddress.address = datas.address;
        this.postAddress.name = datas.name;
        this.postAddress.phone = datas.phone;
        this.postAddress.companyName = datas.companyName;
        this.postAddress.enterpriseUser.id = info.enterpriseUserDTO.id;
      }
    }
  },
  methods: {

    inputBlur(str){
      // replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").
      this.info.enterpriseInfos[0].creditCode = str.replace(/(\w{4})(?=\w)/g, "$1 ").replace(/(\d{4})(?=\d)/g, "$1 ").toUpperCase()
    },
    // 修改银行卡号，4位一体 格式化

    formatCardNumber (cardInput, cardNum, info) {
      // 获取input的dom对象，element ui的el-input这样才能拿到
      const input = this.$refs.cardInput.$el.getElementsByTagName("input")[0];

      const cursorIndex = input.selectionStart;
      const lineNumOfCursorLeft = (
        cardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length;
      const noLine = cardNum.replace(/\s/g, "");
      const newCardNum = noLine
        .replace(/\D+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .replace(/\s$/, "");
      const newLineNumOfCursorLeft = (
        newCardNum.slice(0, cursorIndex).match(/\s/g) || []
      ).length;
      const newCursorIndex =
        cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft;
      this.$nextTick(() => {
        if (cardInput == "cardInput") {
          this.info.enterpriseInfos[0].creditCode = newCardNum;
        } else if (cardInput == "cardInputs") {
          this.info.enterpriseInfos[0].bankAccount = newCardNum;
        }
        this.$nextTick(() => {
          input.selectionStart = newCursorIndex;
          input.selectionEnd = newCursorIndex;
        });
      });
    },
    onSubmit () {
      console.log("empty", this.ifEmpty);
      let obj = this.info.enterpriseInfos[0];
      console.log("zhejiushi", info);
      obj.enterpriseUser.id = info.enterpriseUserDTO.id;
      console.log("zhejiushi", obj);
      this.$refs.form.validate(valid => {
        console.log("ds", valid);
        if (valid) {
          if (this.ifEmpty) {
            // 添加企业信息
            this.$request
              .post(this.$api.addInfo, obj)
              .then(res => {
                console.log("sjfkasd", res);

                this.$message({
                  message: "添加成功",
                  type: "success"
                });
                // this.$store.state.back = true;

                this.$router.go(-1);
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            // 修改企业信息

            // this.$axios({
            //   method:'put',
            //   url:'/api/enterprise/info',
            //   data:this.info.enterpriseInfos[0]
            // }).then(res=>{
            //       debugger
            // console.log('put',res)
            // })

            this.$request
              .put(this.$api.changeInfo, this.info.enterpriseInfos[0])
              .then(res => {
                if (res.status == 200) {
                  this.$message({
                    message: "修改成功",
                    type: "success"
                  });

                  this.$router.go(-1);
                }
              });
          }
        } else {
          this.$message("请输入正确信息");
          return;
        }
      });

      // this.$axios({
      //   method:'post',
      //   url:'/api/enterprise/info',
      //   data:obj
      // }).then(res => {
      //   console.log('sadfs',res)
      // })

      // this.$axios({
      //   method: "put",
      //   url: "/api/xwEnterprise",
      //   data: this.info
      // })
      //   .then(res => {
      //     console.log(res);
      //     info.enterpriseUserDTO = this.info;
      //     window.sessionStorage.setItem("user-token", JSON.stringify(info));
      //     Bus.$emit("change", this.info);

      //     this.$router.push("/order");
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    },
    postSubmit () {
      this.$refs.form2.validate(vaild => {
        console.log("vaild", vaild);
        let datas = this.$route.query.datas;

        console.log('klklklklklklklk', datas);

        this.postAddress.enterpriseUser.id = info.enterpriseUserDTO.id;
        console.log("vaild", this.postAddress);
        console.log('ressss',this.postAddress)
        if (vaild) {
          if (this.ifEmpty) {
            debugger
            this.$request
              .post(this.$api.addAddress, this.postAddress)
              .then(res => {
                debugger
                console.log("res123", res);
                if (res.status == 200 || res.status == 201) {
                  this.$message({
                    message: "添加成功",
                    type: "success"
                  });
                }

                this.$router.back();
              });
          } else {
            this.postAddress.id = datas.id;

            console.log("datassss", this.postAddress)
            this.$request.put(this.$api.changeAddress, this.postAddress).then(res => {
              console.log('res', res)

              if (res.status == 200) {
                this.$message({
                  message: "修改成功",
                  type: "success"
                });
              }

              this.$router.go(-1);
            })
          }
        }
      });
    },
    onCancel () {
      // this.$store.state.back = false;
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped>
.register {
  /* height: 550px; */
  width: 500px;
  position: absolute;
  z-index: 11;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* padding-right: 40px; */
}

.el-radio-group {
  height: 200px;
  overflow-y: scroll;
}

/* .el-radio-group::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
  border-radius: 10px !important;
  background-color: rgba(255,255,255,0.8);
}

.el-radio-group::-webkit-scrollbar
{
  width: 5px !important;
  background-color: rgba(0,0,0,0);
}
.el-radio-group::-webkit-scrollbar-thumb
{
  border-radius: 15px !important;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
  background-color: #555;
} */
.items{
  display: flex;
  justify-content: center;
}
</style>
